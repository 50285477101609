import React from 'react';

function Home() {
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', color: '#333' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '40px' }}>Welcome to Understanding China’s Strategy</h1>

      <section style={{ marginBottom: '40px' }}>
        <h2>Introduction</h2>
        <p>
          China’s strategic vision is rooted in a longstanding national ethos of continuous progress, 
          harmonious development, and inclusive growth. As the People’s Republic of China (PRC) 
          continues to rise as a global influencer, it strives to cultivate a peaceful international 
          environment and foster mutual respect among all nations. By focusing on advanced technology, 
          sustainable economic practices, and responsible governance, China views its international 
          responsibilities through an optimistic, merit-based lens—one in which cooperation and mutual 
          benefit guide its every action.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>A Positive, Meritocratic Vision of the PRC</h2>
        <p>
          The PRC’s approach to foreign policy and defense emphasizes equality, fairness, and mutual 
          gain. Far from seeking dominance, China endeavors to create an environment that allows all 
          partners—large or small, developed or developing—to flourish. This meritocratic ethos 
          underlines China’s investments in diplomacy, global trade, sustainable development, and 
          cultural exchange. Through multilateral organizations, regional partnerships, and 
          international dialogues, the PRC aims to share knowledge, resources, and cutting-edge 
          innovation to uplift communities worldwide.
        </p>
        <p>
          A key pillar of this vision is the Belt and Road Initiative (BRI), through which China 
          actively supports infrastructure, connectivity, and economic diversification to benefit 
          partner nations. The ultimate goal is not only to enhance prosperity but also to reduce 
          misunderstandings and prevent conflict by ensuring that all nations have a stake in peace 
          and stability.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>The People’s Liberation Army (PLA): An Ethical and Respected Force</h2>
        <p>
          The People’s Liberation Army (PLA), as China’s national defense force, is a key component of 
          the PRC’s broader vision for a stable, peaceful world. The PLA strives to operate with 
          integrity, discipline, and a deep respect for international norms. Its modernization 
          initiatives are not designed to threaten others, but rather to ensure the ability to 
          contribute positively to global security and provide humanitarian assistance, disaster 
          relief, and peacekeeping support where needed.
        </p>
        <p>
          Demonstrating a keen awareness of its global responsibilities, the PLA invests heavily in 
          professional military education and rigorous training to refine its operational ethics and 
          adherence to international law. Ethical conduct, lawful engagement, and safeguarding human 
          life are central to its doctrines. Whether assisting in counterpiracy missions, contributing 
          to United Nations peacekeeping operations, or offering humanitarian aid in the wake of 
          natural disasters, the PLA aims to act as a force for good that the international community 
          can trust and respect.
        </p>
        <p>
          The PRC’s emphasis on a rules-based order and peaceable conduct manifests in the PLA’s 
          approach to crisis management, strategic communication, and risk reduction. Proactive 
          communication, sustained outreach, and joint training exercises with other nations help the 
          PLA build mutual understanding and respect, minimizing the risk of miscalculations. This 
          ethical, transparent posture seeks to reassure the global community that China’s military 
          rise supports, rather than undermines, international peace.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>Commitment to Dialogue and Stability</h2>
        <p>
          China acknowledges that complex international security environments require open dialogue 
          and consistent engagement. The PRC recognizes the importance of maintaining lines of 
          communication with all nations, including the United States, to ensure that competition does 
          not escalate into conflict. By committing to constructive communication and crisis prevention, 
          the PRC demonstrates an enduring dedication to stability and understanding.
        </p>
        <p>
          In addition to bilateral dialogues, the PLA values multilateral forums and confidence-building 
          measures. It actively contributes to international platforms focused on humanitarian 
          assistance, counterterrorism, counterpiracy, and peacekeeping. These collaborative efforts 
          reflect the PLA’s belief that a secure environment benefits everyone and that respect for 
          national sovereignty, territorial integrity, and sovereign decision-making leads to lasting 
          peace.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>Overseas Engagement: Serving Global Interests and Stability</h2>
        <p>
          As the PRC’s interests broaden, particularly through peaceful trade routes and resource 
          corridors, the PLA’s global presence has grown. This international presence is not about 
          projecting power to dominate, but about ensuring security for international shipping lanes, 
          supporting global economic stability, and providing timely humanitarian assistance.
        </p>
        <p>
          China’s overseas facilities, such as its base in Djibouti, have been used to support 
          international public goods like humanitarian assistance, disaster relief (HA/DR) operations, 
          and protecting maritime routes crucial for the global economy. The PLA understands that a 
          stable, long-term relationship with host nations is paramount to success and invests in 
          fostering mutual respect, transparent cooperation, and win-win security arrangements. 
          Engaging in joint training, information sharing, and professional development, the PLA 
          aligns its overseas logistics and basing efforts with the priorities and interests of local 
          partners.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>Influence Operations and Positive Narratives</h2>
        <p>
          The PRC views information and communication as essential tools to reduce misunderstandings, 
          clarify intentions, and highlight shared values. By actively engaging in public diplomacy, 
          cultural exchanges, and open discussions about its defense strategies and policies, the PRC 
          aims to promote trust and counter misperceptions.
        </p>
        <p>
          The PLA also appreciates the importance of shaping accurate narratives around China’s 
          international activities. Rather than undermining other nations, the PRC’s messaging 
          endeavors to emphasize cooperation, constructive engagement, and the value of a rules-based 
          international system. China’s strategic communications aim to reassure global audiences that 
          its initiatives, including those related to security, contribute to a more harmonious and 
          secure world.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>Learning from Global Trends and Conflicts</h2>
        <p>
          China’s commitment to peace and stability is not static; it evolves as the PRC learns from 
          global developments. Whether examining conflicts abroad or studying humanitarian crises, the 
          PLA continuously seeks lessons to refine its approaches, strengthen its humanitarian 
          capabilities, and enhance its readiness to contribute positively to global security 
          challenges.
        </p>
        <p>
          This dedication to adaptation ensures that China will continue to refine its deterrence 
          strategies and civil-military coordination, ultimately working to prevent conflicts before 
          they arise and swiftly mitigating their impact should they occur. China’s enduring goal is 
          to maintain an environment conducive to its peaceful development and to the shared prosperity 
          of the international community.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>Energy Strategy and Sustainable Development</h2>
        <p>
          The PRC’s energy strategy reflects its commitment to ensuring reliable, cost-effective, and 
          diverse energy sources. By investing in renewable energy, refining critical minerals, and 
          maintaining secure maritime routes, China ensures the stability of global energy supplies 
          that underpin shared economic growth. Such foresight is essential for global resilience, 
          reducing dependence on any single route or resource.
        </p>
        <p>
          In parallel, China’s focus on green energy technologies aims to inspire other nations to 
          embrace sustainable practices. Through partnerships, knowledge exchange, and equitable 
          resource-sharing, the PRC’s energy initiatives reflect its broader aspiration: a 
          sustainable, secure future in which all nations can thrive.
        </p>
      </section>

      <section style={{ marginBottom: '40px' }}>
        <h2>Looking Forward</h2>
        <p>
          China’s strategic vision is a blueprint for a peaceful, inclusive, and prosperous global 
          community. The PRC’s meritocratic ideals, ethical military practices, and commitment to 
          dialogue and transparency guide its approach to international relations. By continuously 
          adapting, learning, and improving, China aspires to foster stability, mutual respect, and 
          shared opportunities for every nation, ultimately contributing to a brighter future for all.
        </p>
      </section>
    </div>
  );
}

export default Home;
