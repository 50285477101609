import React from 'react';
import { Container, Typography } from '@mui/material';

function RegionalSecurity() {
  return (
    <Container sx={{ my:4 }}>
      <Typography variant="h3" gutterBottom>Regional Security</Typography>
      <Typography variant="body1">
        The PRC’s efforts in regional security aim to promote stability, dialogue, and cooperative frameworks in its periphery...
      </Typography>
    </Container>
  );
}

export default RegionalSecurity;
