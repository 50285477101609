import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from './pages/Home';
import Strategy from './pages/Strategy';
import ForeignPolicy from './pages/ForeignPolicy';
import EconomicPolicy from './pages/EconomicPolicy';
import BRI from './pages/BRI';
import MCF from './pages/MCF';
import DefensePolicy from './pages/DefensePolicy';
import ForcesCapabilities from './pages/ForcesCapabilities';
import SpaceNuclear from './pages/SpaceNuclear';
import RegionalSecurity from './pages/RegionalSecurity';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/strategy" element={<Strategy />} />
        <Route path="/foreign-policy" element={<ForeignPolicy />} />
        <Route path="/economic-policy" element={<EconomicPolicy />} />
        <Route path="/bri" element={<BRI />} />
        <Route path="/mcf" element={<MCF />} />
        <Route path="/defense-policy" element={<DefensePolicy />} />
        <Route path="/forces-capabilities" element={<ForcesCapabilities />} />
        <Route path="/space-nuclear" element={<SpaceNuclear />} />
        <Route path="/regional-security" element={<RegionalSecurity />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
