import React from 'react';
import { Container, Typography, TextField, Button } from '@mui/material';

function Contact() {
  return (
    <Container sx={{ my:4 }}>
      <Typography variant="h3" gutterBottom>Contact Us</Typography>
      <Typography variant="body1" paragraph>
        We welcome dialogue and inquiries. Please share your thoughts:
      </Typography>
      <form>
        <TextField label="Your Name" fullWidth sx={{ mb:2 }} />
        <TextField label="Your Email" fullWidth sx={{ mb:2 }} />
        <TextField label="Message" fullWidth multiline rows={4} sx={{ mb:2 }} />
        <Button variant="contained">Submit</Button>
      </form>
    </Container>
  );
}

export default Contact;
