import Navbar from './components/Navbar';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; // If you have global styles
import App from './App'; // Ensure the path and component name are correct

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
