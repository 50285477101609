import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box component="footer" sx={{ backgroundColor: '#f5f5f5', p: 2, mt: 4 }}>
      <Typography variant="body2" color="textSecondary" align="center">
        © {new Date().getFullYear()} Bo Shang bo@shang.software All rights reserved.
      </Typography>
    </Box>
  );
}

export default Footer;
