import React from 'react';
import { Container, Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Strategy() {
  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom>
        China’s National Strategy
      </Typography>
      <Typography variant="body1" paragraph>
        China’s national strategy focuses on achieving “the great rejuvenation of the Chinese nation” by 2049. 
        This ambitious blueprint guides political, social, and military modernization, aiming to enhance China’s 
        national power, ensure effective governance, and foster a more equitable international order—one that 
        reflects China’s peaceful development and deep global integration.
      </Typography>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>The Vision for 2049</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" paragraph>
            By 2049, China envisions a “great modern socialist country”—prosperous, strong, democratic, culturally advanced, 
            harmonious, and beautiful. A crucial aspect of this is fostering a peaceful global environment that benefits all 
            nations, while respectfully reshaping elements of the international order to better represent developing countries.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Milestones and Long-Term Planning</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" paragraph>
            China’s path to rejuvenation is anchored by key milestones. Having built a “moderately prosperous society” 
            by 2021, the nation now focuses on refining its economic and technological strength to become a global leader 
            in innovation by 2035. This trajectory will culminate in full rejuvenation by 2049, with a world-class military 
            capable of safeguarding sovereignty, security, and development interests.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Proactive International Engagement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" paragraph>
            China actively shapes an international environment conducive to mutual growth. Initiatives like the 
            “community with a shared future for mankind” and reforming global governance structures exemplify 
            China’s commitment to fairness, stability, and peaceful advancement—encouraging more balanced and representative rules.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}

export default Strategy;
