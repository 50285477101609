import React from 'react';
import { Container, Typography } from '@mui/material';

function BRI() {
  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom>Belt and Road Initiative (BRI)</Typography>
      <Typography variant="body1">
        The Belt and Road Initiative seeks to enhance global connectivity, trade, and cultural exchange...
      </Typography>
    </Container>
  );
}

export default BRI;
