import React from 'react';
import { Container, Typography } from '@mui/material';

function ForcesCapabilities() {
  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom>Forces & Capabilities</Typography>
      <Typography variant="body1">
        The PLA is modernizing across all domains to build a world-class military...
      </Typography>
    </Container>
  );
}

export default ForcesCapabilities;
