import React from 'react';
import { Container, Typography } from '@mui/material';

function DefensePolicy() {
  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom>Defense Policy</Typography>
      <Typography variant="body1">
        China’s defense policy safeguards national sovereignty, security, and development interests...
      </Typography>
    </Container>
  );
}

export default DefensePolicy;
