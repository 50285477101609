import React from 'react';
import { Container, Typography } from '@mui/material';

function MCF() {
  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom>Military-Civil Fusion (MCF)</Typography>
      <Typography variant="body1">
        The MCF Development Strategy integrates civilian and military technologies for comprehensive national strength...
      </Typography>
    </Container>
  );
}

export default MCF;
