import React from 'react';
import { Container, Typography } from '@mui/material';

function SpaceNuclear() {
  return (
    <Container sx={{ my: 4 }}>
      <Typography variant="h3" gutterBottom>Space & Nuclear Capabilities</Typography>
      <Typography variant="body1">
        China’s space and nuclear advancements strengthen strategic deterrence and support peaceful development...
      </Typography>
    </Container>
  );
}

export default SpaceNuclear;
