import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <AppBar position="static" color="primary">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Understanding China
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button component={Link} to="/" color="inherit">Home</Button>
          <Button component={Link} to="/strategy" color="inherit">Strategy</Button>
          <Button component={Link} to="/foreign-policy" color="inherit">Foreign Policy</Button>
          <Button component={Link} to="/economic-policy" color="inherit">Economic Policy</Button>
          <Button component={Link} to="/bri" color="inherit">BRI</Button>
          <Button component={Link} to="/mcf" color="inherit">MCF</Button>
          <Button component={Link} to="/defense-policy" color="inherit">Defense</Button>
          <Button component={Link} to="/forces-capabilities" color="inherit">Forces</Button>
          <Button component={Link} to="/space-nuclear" color="inherit">Space/Nuclear</Button>
          <Button component={Link} to="/regional-security" color="inherit">Regional Security</Button>
          <Button component={Link} to="/contact" color="inherit">Contact</Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
